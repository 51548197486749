import { appStores } from 'store';

export default function isAccess(permissionsOrFeatures) {
  if (!permissionsOrFeatures) return false;
  if (Array.isArray(permissionsOrFeatures)) {
    return permissionsOrFeatures.every(permissionOrFeature =>
      permissionOrFeature.startsWith('!')
        ? !isAccessOrEnabledFunction(permissionOrFeature.slice(1))
        : isAccessOrEnabledFunction(permissionOrFeature)
    );
  }

  if (typeof permissionsOrFeatures === 'string') {
    if (permissionsOrFeatures.startsWith('!')) {
      return !isAccessOrEnabledFunction(permissionsOrFeatures.slice(1));
    }
    return isAccessOrEnabledFunction(permissionsOrFeatures);
  }

  return false;
}

function isAccessOrEnabledFunction(permissionOrFeature) {
  return isAccessFunction(permissionOrFeature) || isEnabledFunction(permissionOrFeature);
}

/**
 * @return {JustSessionUserData | undefined}
 */
function getCurrentUserData() {
  return appStores.CurrentUser.getState().user;
}

/**
 * @return {boolean}
 */
export function isInternal() {
  const currentUserData = getCurrentUserData();
  return currentUserData && currentUserData.internal;
}

export function isSAdmin() {
  const currentUserData = getCurrentUserData();
  return currentUserData && currentUserData.permissions.some(prem => prem === 'ACCOUNTS_READ');
}

function getPermissionsOrFeaturesArr(field) {
  const pathname = window.location.pathname;
  const selectedAccountId = pathname.includes('/accounts/')
    ? parseInt(pathname.substring(pathname.lastIndexOf('/') + 1))
    : null;

  const currentUserData = getCurrentUserData();

  if ((!selectedAccountId && selectedAccountId !== 0) || selectedAccountId === currentUserData.accountId) {
    return currentUserData[field];
  } else {
    const selectedAccount = currentUserData.accounts.find(account => account.accountId === selectedAccountId);
    return selectedAccount[field];
  }
}

function isAccessFunction(str) {
  if (str === 'ALL') return true;
  try {
    return getPermissionsOrFeaturesArr('permissions').some(permission => permission === str);
  } catch (e) {
    return false;
  }
}

function isEnabledFunction(featureName) {
  try {
    return getPermissionsOrFeaturesArr('features').some(feature => feature === featureName);
  } catch (e) {
    return false;
  }
}

export function isLoggedIn() {
  let currentUser = getCurrentUserData();
  if (currentUser) return currentUser;
  try {
    currentUser = JSON.parse(localStorage.CURRENT_USER);
  } catch (e) {
    currentUser = null;
  }
  return !!currentUser;
}

export const isAffiliateProgramEnabled = () => appStores.Config.getState()?.options?.affiliateProgramEnabled;
export const isCcBillingEnabled = () => appStores.Config.getState()?.options?.ccBillingEnabled;
export const isEuroInstance = () => appStores.Config.getState().options.euroInstance;
