import { useCallback, useState, useRef } from 'react';
const emptyObj = {};
export default function usePromisifyComponent(callback, handlers, deps = []) {
    if (handlers === undefined)
        handlers = emptyObj;
    const [opened, setOpen] = useState(false);
    const innerCallback = useCallback(callback, [callback, opened, ...deps]);
    const promise = useRef();
    const onClose = useCallback(() => {
        var _a;
        setOpen(false);
        (_a = promise.current) === null || _a === void 0 ? void 0 : _a.reject();
        promise.current = undefined;
        (handlers === null || handlers === void 0 ? void 0 : handlers.onReject) && handlers.onReject();
    }, [handlers]);
    const onResolve = useCallback(data => {
        setOpen(false);
        promise.current.resolve(data);
        promise.current = undefined;
        return (handlers === null || handlers === void 0 ? void 0 : handlers.onResolve) ? handlers.onResolve(data) : null;
    }, [handlers]);
    const openProps = useRef();
    const open = useCallback((props) => {
        openProps.current = props;
        setOpen(true);
        let resolveRef;
        let rejectRef;
        let promiseRef = new Promise((resolve, reject) => {
            resolveRef = resolve;
            rejectRef = reject;
        });
        promise.current = {
            promise: promiseRef,
            resolve: resolveRef,
            reject: rejectRef,
        };
        return promise.current.promise.finally(() => {
            openProps.current = undefined;
        });
    }, [openProps]);
    return [innerCallback(onResolve, onClose, opened, openProps.current), open, onClose, opened];
}
