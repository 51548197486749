export const inviteToProductLocalization = {
  'InviteToProductForm:Header': {
    ru: 'Пригласить пользователей',
    eng: 'Invite users',
  },
  'InviteToProductForm:Back': {
    ru: 'Назад в аккаунт',
    eng: 'Back to account',
  },
  'InviteToProductForm:Field:Roles': {
    ru: 'Группа и роли',
    eng: 'Groups and roles',
  },
  'InviteToProductForm:Field:Users': {
    ru: 'Пользователи',
    eng: 'Users',
  },
  'InviteToProductForm:Submit': {
    ru: 'Пригласить',
    eng: 'Invite',
  },
  'InviteToProductForm:InviteMessage': {
    ru: 'Приглашение отправлено! Ссылка будет доступна получателю в течение следующих {invitationLimit} дней.',
    eng: 'Invitation sent! The link will be available to the recipient for the next {invitationLimit} days.',
  },
  'InviteToProductForm:Field:Users:Info:Invite': {
    ru: 'Укажите email пользователей, которых хотите пригласить. Если у пользователя не указан email в профиле, его не получится пригласить. Подробнее читайте в <a target="_blank" href={link} rel="noopener noreferrer">документации</a>.',
    eng: 'Specify emails of the users you want to invite. If a user doesn’t have an email listed in their profile, they cannot be invited. Read more in the  <a target="_blank" href={link} rel="noopener noreferrer">documentation</a>.',
  },
  'InviteToProductForm:ChoosedProduct': {
    ru: 'Продукт',
    eng: 'Product',
  },
  'InviteToProductForm:DocsLink:Text': {
    ru: 'Какие есть роли в продуктах',
    eng: 'What are the roles in the products',
  },
  'InviteToProductForm:DocsLink:Link': {
    ru: '#{config.helpUrl}/accounts/roles-permissions/',
    eng: '#{config.helpUrl}/accounts/roles-permissions/',
  },
  'InviteToProductForm:DocsLink:Invite:Link': {
    ru: '#{config.helpUrl}/accounts/#invite-users',
    eng: '#{config.helpUrl}/accounts/#invite-users',
  },
  'InviteToProductForm:Alert:Title': {
    ru: 'Приглашение пользователей',
    eng: 'Inviting users',
  },
  'InviteToProductForm:Alert:Message': {
    ru: 'Приглашения отправлены на следующие email:',
    eng: 'Invitations were sent to the following emails:',
  },
  'InviteToProductForm:Alert:Message:Empty': {
    ru: 'Нет отправленных приглашений из-за ошибок',
    eng: 'No invitations sent due to errors',
  },
  'InviteToProductForm:ResponseField:Limit': {
    ru: 'Превышен лимит пользователей',
    eng: 'User limit exceeded',
  },
  'InviteToProductForm:Error:Validation': {
    ru: 'Адреса email имеют некорректный формат: {emailsWithError}',
    eng: 'The email addresses have incorrect format: {emailsWithError}',
  },

  'InviteToProductForm:ResponseField:failedInvalidEmails': {
    ru: 'Проверьте правильностей этих email:',
    eng: 'Please check the validity of these emails:',
  },
  'InviteToProductForm:ResponseField:failedMaxUsersReached': {
    ru: 'Превышен лимит в {maxUsersPerAccount} {users} для аккаунта. Этим пользователям приглашения отправлены не будут:',
    eng: 'You’ve exceeded the limit of {maxUsersPerAccount} {users} for your account. These users will not receive invitations:',
  },
  'InviteToProductForm:ResponseField:failedRegistrationDisabled': {
    ru: 'Для выбранного продукта вы можете приглашать только зарегистрированных пользователей. Эти пользователи не зарегистрированы, им не будет отправлено приглашение:',
    eng: 'For the selected product, you can only invite registered users. These users are not registered and will not receive invitations:',
  },
  'InviteToProductForm:ResponseField:failedAlreadyMembersOfAccount': {
    ru: 'Эти пользователи уже состоят в вашем аккаунте:',
    eng: 'These users are already members of your account:',
  },
  'InviteToProductForm:ResponseField:failedAlreadyInvited': {
    ru: 'Эти пользователи уже получили приглашение в ваш аккаунт:',
    eng: 'These users have already been invited to your account:',
  },
  'InviteToProductForm:Error:accountsadmin.captcha.missing.header': {
    ru: 'Произошла внутренняя ошибка. Пожалуйста, обратитесь в техническую поддержку.',
    eng: 'An internal error occurred. Please contact out technical support.',
  },
  'InviteToProductForm.ResponseField:Users:one': {
    ru: 'пользователя',
    eng: 'user',
  },
  'InviteToProductForm.ResponseField:Users:few': {
    ru: 'пользователя',
    eng: 'users',
  },
  'InviteToProductForm.ResponseField:Users:many': {
    ru: 'пользователей',
    eng: 'users',
  },
  'InviteToProductForm.ResponseField:Users:other': {
    ru: 'пользователей',
    eng: 'users',
  },
};
